
import ImageSingle from '~/patterns/atoms/image-single/image-single.vue';
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';

export default {
    components: {
        ImageSingle,
        GwPageContainer
    },
    props: {
        url: {
            type: String,
            default: null
        },
        webpUrl: {
            type: String,
            default: null
        },
        urlTiny: {
            type: String,
            default: null
        },
        width: {
            type: Number,
            default: null
        },
        height: {
            type: Number,
            default: null,
        },
        srcset: {
            type: String,
            default: null
        },
        srcsetWebp: {
            type: String,
            default: null
        }
    }
};
