'use strict';

import { isEmpty, trim } from 'lodash';
import config from '~/config/config';

export default {
    computed: {
        seoEntry() {
            return this.entry;
        },
        seoTitle() {
            if (this.seoEntry?.seo?.title) {
                // Unescape ampersands
                if (this.seoEntry?.seo?.title.indexOf('&amp;') !== -1) {
                    return this.seoEntry?.seo?.title.replace('&amp;', '&');
                }

                return this.seoEntry.seo.title;
            }

            if (this.seoEntry && this.seoEntry.title) {
                // On homepage put brand name as prefix
                if (this.$route.path === '/') {
                    return this.$config.site.name + ' | ' + this.seoEntry.title;
                }

                return this.seoEntry.title + ' | ' + this.$config.site.name;
            }

            return this.$config.site.name;
        },
        seoDescription() {
            if (this.seoEntry) {
                if (this.seoEntry.seo && this.seoEntry.seo.description) {
                    return this.seoEntry.seo.description;
                } else if (this.seoEntry.description) {
                    return this.seoEntry.description;
                }
            }

            return this.$config.site.description;
        },
        seoRobots() {
            if (this?.seoEntry?.seo?.advanced?.robots) {
                return this.seoEntry.seo.advanced.robots.map((content) => {
                    return {
                        name: 'robots',
                        content,
                    };
                });
            }

            return [];
        },
        seoImage() {
            if (this.seoEntry) {
                if (this.seoEntry.featuredImage && this.seoEntry.featuredImage[0] && this.seoEntry.featuredImage[0].w768) {
                    return this.seoEntry.featuredImage[0];
                } else if (this.seoEntry.image && this.seoEntry.image[0] && this.seoEntry.image[0].w768) {
                    return this.seoEntry.image[0];
                } else if (this.seoEntry.imageMobile && this.seoEntry.imageMobile[0] && this.seoEntry.imageMobile[0].w768) {
                    return this.seoEntry.imageMobile[0];
                } else if (this.seoEntry.imageCard && this.seoEntry.imageCard[0] && this.seoEntry.imageCard[0].w768) {
                    return this.seoEntry.imageCard[0];
                }
            }
            return '';
        },
        seoSocial() {
            if (this.seoEntry && this.seoEntry.seo) {
                return this.seoEntry.seo.social || {};
            }

            return {};
        },
        seoFacebook() {
            if (this.seoSocial) {
                return this.seoSocial.facebook || {};
            }

            return {};
        },
        seoTwitter() {
            if (this.seoSocial) {
                return this.seoSocial.twitter || {};
            }

            return {};
        },
        seoFacebookImage() {
            return this.seoFacebook?.image ?? this.seoFallbackSocialImage;
        },
        seoTwitterImage() {
            return this.seoTwitter?.image ?? this.seoFallbackSocialImage;
        },
        seoFallbackSocialImage() {
            switch (this.$config.site.group) {
            case 'highberg':
                return {
                    w768: 'https://www.gladwellacademy.com/seo/HA_logo_1200x630_RGB.png',
                };
            case 'gladwell':
                return {
                    w768: 'https://www.gladwellacademy.com/seo/GW_logo_1200x630_RGB.png',
                };
            default:
                return {};
            }
        },
        seoMeta() {
            return [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.seoDescription || '',
                },
                // FACEBOOK
                {
                    hid: 'og:type',
                    property: 'og:type',
                    content: 'website',
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: this.seoFacebook.title || this.seoTitle,
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: this.seoFacebook.description || this.seoDescription || '',
                },
                {
                    hid: 'og:image',
                    property: 'og:image',
                    content: this.seoFacebookImage.w768 || this.seoImage.w768 || this.seoTwitterImage.w768,
                },
                // TWITTER
                {
                    hid: 'twitter:title',
                    property: 'twitter:title',
                    content: this.seoTwitter.title || this.seoTitle,
                },
                {
                    hid: 'twitter:description',
                    property: 'twitter:description',
                    content: this.seoTwitter.description || this.seoFacebook.description || this.seoDescription || '',
                },
                {
                    hid: 'twitter:image',
                    property: 'twitter:image',
                    content: this.seoTwitterImage.w768 || this.seoImage.w768 || this.seoFacebookImage.w768,
                },
            ];
        },
        seoCanonical() {
            return this?.seoEntry?.seo?.advanced?.canonical;
        },
        seoAttributeLinks() {
            const links = [];
            let xDefaultFound = false;

            // get all the sites from config.sites where the group property matches this.$config.site.group.
            const matchingSites = Object.values(config.sites).filter(site => site.group === this.$config.site.group);
            // Create hreflang links based on CMS data
            if (!isEmpty(this.entry?.localized)) {
                this.entry.localized.forEach((locale) => {
                    // Only add hreflang links for sites that match the current group
                    if (!matchingSites.find(site => site.siteHandle === locale.siteHandle)) {
                        return;
                    }
                    links.push({
                        hid: 'i18n-alt-' + locale.language,
                        rel: 'alternate',
                        hreflang: locale.language,
                        href: locale.url,
                    });

                    if (!xDefaultFound && (locale.language === 'en' || locale.language === 'en-US')) {
                        // Create x-default hreflang link
                        xDefaultFound = true;
                        links.push({
                            hid: 'i18n-alt-xd',
                            rel: 'alternate',
                            hreflang: 'x-default',
                            href: locale.url,
                        });
                    }
                });
            }

            if (!xDefaultFound) {
                // Add x-default for the current group, either highbergacadey.com or gladwellacademy.com
                if (this.$config.site.group === 'highberg') {
                    links.push({
                        hid: 'i18n-alt-xd',
                        rel: 'alternate',
                        hreflang: 'x-default',
                        href: 'https://www.highbergacademy.com/',
                    });
                } else {
                    links.push({
                        hid: 'i18n-alt-xd',
                        rel: 'alternate',
                        hreflang: 'x-default',
                        href: 'https://www.gladwellacademy.com/',
                    });
                }
            }

            // Current page provided by Nuxt
            const fullURL = trim(this.$config.baseURL, '/') + this.$route.fullPath;

            // Create self-referencing hreflang link
            links.push({
                hid: 'i18n-alt-' + this.$i18n.locale,
                rel: 'alternate',
                hreflang: this.$i18n.locale,
                href: this.entry?.url || fullURL,
            });

            // Create self-referencing canonical link
            links.push({
                hid: 'i18n-can',
                rel: 'canonical',
                href: this.seoCanonical || fullURL,
            });

            return links;
        },
        seoHead() {
            const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: false });
            return {
                htmlAttrs: {
                    ...i18nHead.htmlAttrs,
                },
                title: this.seoTitle,
                meta: [
                    ...this.seoMeta,
                    ...this.seoRobots,
                ],
                link: this.seoAttributeLinks,
            };
        },
    },
    head() {
        return this.seoHead;
    },

};
